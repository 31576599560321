/** Top level ion-toolbar component */
#nav-bar {
    --min-height: 70px;
    --background: #D1D1D1;
}
/** Flex container for logo and text */
#title-container {
    margin: 0 0 0 10px;
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}
/* !!!!!!!!!!!!!!!!!!!!!!!!!!!!! */
/* ! Experimental stacked icon ! */
/* !!!!!!!!!!!!!!!!!!!!!!!!!!!!! */
/** Container for stacked icon UI */
#icon-grid {
    display: grid;
    height: 50px;
    width: 50px;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(3, 1fr);
}
#icon-grid > div {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: var(--primaryBPTint);
}
#icon-grid > div > img {
    height: 50%;
}
/** Icon layers */
.full-icon { grid-area: 1 / 1 / 4 / 4; }
.lower-icon { grid-area: 1 / 1 / 3 / 3; }
.upper-icon { grid-area: 2 / 2 / 4 / 4; }


/** Onsite safety title */
#oss-title-text {
    height: 80%;
}
/** Navigation icons (Home and menu) */
#nav-bar ion-icon {
    color: var(--logoGrey);
}
/* ! EXPERIMENTAL MENU BUTTON  */
#menu-button {
    margin-right: 10px;
    width: 45px;
    height: 35px;
    --padding-start: 0;
    --padding-end: 0;
    --border-radius: 0;
}
#lines-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
#lines-container div {
    flex-grow: 1;
}
#lines-container div:nth-child(odd) {
    width: 100%;
    background-color: var(--primaryBP);
}
/** Tape */
#tape-div {
    width: 100%;
    height: 20px;
    background-color: var(--dangerBP);
    background-image: url(../../public/assets/ui_images/Tape.png);
    background-repeat: repeat-x;
    background-size: auto 100%;
}