/** Top level modal element */
.risk-modal {
    /* --height: 90vh; */
}
/* ########## */
/* # Header # */
/* ########## */
/** Grey toolbar at top of modal */
.modal-toolbar {
    --background: #D1D1D1;
    --min-height: 20px;
    color: var(--logoGrey);
}
.center-content {
    display: flex;
    justify-content: center;
}
/** Modal title */
.modal-toolbar ion-title {
    padding-left: 10px;
    font-size: 2.2rem;
    font-weight: bold;
}
/** Close button  */
.modal-toolbar ion-button {
    --padding-top: 0;
    --padding-end: 0;
    --padding-bottom: 0;
    --padding-start: 0;
}
.modal-toolbar ion-icon {
    color: var(--logoGrey);
}

/* ########### */
/* # Content # */
/* ########### */
/** Modal content  */
.risk-modal ion-content {
    --padding-top: 10px;
    --padding-end: 10px;
    --padding-bottom: 10px;
    --padding-start: 10px;
}
/* .risk-modal ion-content > div > div {
    box-shadow: 0 0 5px greenyellow;
} */
/** Consequence/likelihood question */
.risk-question {
    font-size: 1.5rem;
}
.selection-ui-container {
    padding: 10px 0 10px 0;
    width: 100%;
    height: 95%;
    display: flex;
    justify-content: center;
    gap: 10px;
}
/** Slider container */
.slider-container {
    height: 100%;
    aspect-ratio: 1 / 10;
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(150, 150, 150);
    border-radius: 7px;
    overflow: hidden;
}
/* ? Slider styles moved to separate file for readability */
/** Ratings container */
.ratings-container {
    height: 100%;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
/** Rating button  */
.ratings-container > ion-button {
    --background: grey;
    height: 10%;
}
/** Text inside rating buttons */
.ratings-container > div > span {
    color: black;
    font-size: 1.2rem;
    font-weight: bold;
}
/* ########## */
/* # Footer # */
/* ########## */
/** Modal footer */
.footer-toolbar {
    --background: #D1D1D1;
    --min-height: 20px;
    color: var(--logoGrey);
}
.footer-buttons ion-button {
    margin: 5px;
    --background: var(--logoGrey);
    color: white;
    border-radius: 5px;
}