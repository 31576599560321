/** Ion content element */
#splash-content {
    --background: var(--primaryBP);
}
/** Flex container for positioning items  */
#splash-container {
    padding: 12vh 7px 4vh 7px;
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
/** Cone logo */
#splash-container > :nth-child(1) {
    height: 15vh;
}
/** Tape divider and app title  */
#tape-title {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
}
/** Caution tape div */
#tape-title div {
    margin: 6vh 0 2vh 0;
    height: 2vh;
    width: 100%;
    background-color: var(--dangerBP);
    background-image: url(../css_images/tape_transparent.svg);
    background-repeat: repeat-x;
    background-size: auto 100%;
}
/** OSS logo */
#tape-title img {
    height: 8vh;
}
/** Builda price branding grid container */
#bp-logo-grid {
    margin-top: auto;
    display: grid;
    grid-template: auto auto / auto auto;
    gap: 1vh;
}
/** Intro span text */
#bp-logo-grid > :nth-child(1) {
    grid-row: 1;
    grid-column: 2;
    font-size: 1.6vh;
    color: white;
}
/** BP icon */
#bp-logo-grid > :nth-child(2) {
    max-height: 6vh;
    grid-row: 2;
    grid-column: 1;
}
/** BP text */
#bp-logo-grid > :nth-child(3) {
    max-height: 6vh;
    grid-row: 2;
    grid-column: 2;
}


/** Button used for test site navigation */
#temp-home {
    position: absolute;
    top: 10px;
    right: 10px;
}