/* ####################### */
/* # range input element # */
/* ####################### */
.vertical-slider {
    height: 10%;
    aspect-ratio: 10 / 1;
    transform: rotate(270deg);
    /* Hiding default slider appearance */
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
}
.vertical-slider:focus {
    outline: none;
}

/* ################# */
/* # Slider thumb  # */
/* ################# */
.vertical-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 10%;
    height: 100%;
    background-color: black;
    -webkit-mask-image: url(../../public/assets/ui_images/slider-thumb.png);
    mask-image: url(../../public/assets/ui_images/slider-thumb.png);
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-size: cover;
    mask-size: contain;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    cursor: pointer;
}
.vertical-slider::-moz-range-thumb {
    appearance: none;
    width: 10%;
    height: 100%;
    background-color: black;
    mask-image: url(../../public/assets/ui_images/slider-thumb.png);
    mask-position: center;
    mask-size: cover;
    mask-repeat: no-repeat;
    border-radius: 0;
    cursor: pointer;
}

/* ################ */
/* # Slider track # */
/* ################ */
.vertical-slider::-webkit-slider-runnable-track {
    width: 100%;
    height: 100%;
    background: gainsboro;
    background: 
        linear-gradient(90deg, rgba(255, 255, 255, 0) var(--sliderPercentage), rgba(150, 150, 150, 255) var(--sliderPercentage)),
        /* linear-gradient(90deg, rgba(0,206,1,1) 0%, rgba(127,231,25,1) 25%, rgba(254,255,48,1) 50%, rgba(254,128,24,1) 75%, rgba(254,0,0,1) 100%); */
        linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(254,14,14,1) 100%);
    cursor: pointer;
}
.vertical-slider::-moz-range-track {
    width: 100%;
    height: 100%;
    background-color: gainsboro;
    background: 
        linear-gradient(90deg, rgba(255, 255, 255, 0) var(--sliderPercentage), rgba(150, 150, 150, 255) var(--sliderPercentage)),
        /* linear-gradient(90deg, rgba(0,206,1,1) 0%, rgba(127,231,25,1) 25%, rgba(254,255,48,1) 50%, rgba(254,128,24,1) 75%, rgba(254,0,0,1) 100%); */
        linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(254,14,14,1) 100%);
    cursor: pointer;
}