/*? Stylesheet for both toolbox talk mockup */
/*? and nested TA mockup */

#return-header {
    padding: 5px;
    background-color: aliceblue;
}
#return-header a {
    font-size: 12px;
}

